


































































































































import type { Link, Picture, FooterOverlay } from '@/inc/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { pushCta, GtmCta, GtmCtaType } from '@/inc/utils'

import UiPicture from '@/components/ui/Picture.vue'

// Simple cta
interface Cta {
  title: string
  text: string
  link: Link
}

// Icon + title
interface CtaPreEncodedDuo {
  icon: string
  link: Link
}

interface CtaPreEncodedDuoPicture extends Cta {
  picture: Picture
}

interface CtaPreEncodedDuoPopup extends Cta {
  icon: string
  link: Link
  popup: string
  htmltext?: string
}

// Orange title
interface CtaPreEncodedCta extends Cta {
  headline: string
}

export interface CtaPreEncoded {
  blockType: 'simple' | 'duo' | 'duo-icon' | 'duo-picture' | 'duo-popup'
  cta?: CtaPreEncodedCta
  duo?: CtaPreEncodedDuo
  duoPicture?: CtaPreEncodedDuoPicture
  duoPopup?: CtaPreEncodedDuoPopup
  duoIcon?: CtaPreEncodedDuo
}

export default defineComponent({
  name: 'CtaPreEncoded',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<CtaPreEncoded>,
      required: true,
    },
  },
  setup(props) {
    const { chrome } = useGetters(['chrome'])
    const rootElRef = ref<HTMLElement | null>(null)
    const popupContentRef = ref<FooterOverlay | null>(null)
    const duos = [props.content.duo, props.content.duoIcon].filter(
      duo => duo !== undefined
    )

    // Push event to datalayer on click
    const pushDatalayer = (
      ctaType: GtmCtaType,
      ctaLabel: string,
      ctaUrl: string
    ) => {
      if (!rootElRef.value) {
        return
      }

      const layer: Partial<GtmCta> = {
        ctaLabel,
        ctaType,
        ctaUrl,
      }

      pushCta(layer, rootElRef.value)
    }

    // Handle click on cta with black label
    const onDuoClick = (duo: CtaPreEncodedDuo, index: number) => {
      let type: GtmCtaType = 'preencoded_left_icon'

      if (index > 0) {
        type = 'preencoded_right_icon'
      }

      if (props.content.duoPicture) {
        type = 'preencoded_picture'
      }

      pushDatalayer(type, duo.link.label as string, duo.link.url)
    }

    // Handle click on cta with orange label
    const onCtaClick = (cta: CtaPreEncodedCta) => {
      let type: GtmCtaType = 'preencoded_center'

      if (props.content.duo) {
        type = 'preencoded_right'
      }

      pushDatalayer(type, `${cta.headline} ${cta.title}`, cta.link.url)
    }

    // Get popup content from chrome
    const onDuoPopupClick = (slug: string, link: Link) => {
      if (!chrome.value.ctas[slug] || !rootElRef.value) {
        return
      }

      const { overlay } = chrome.value.ctas[slug]
      popupContentRef.value = overlay

      // Push datalayer event
      const layer: Partial<GtmCta> = {
        ctaLabel: link.label as string,
        ctaType: 'preencoded_popup',
        ctaUrl: slug,
      }

      pushCta(layer, rootElRef.value)
    }

    // On popup close, nullify content for v-if
    const onPopupClose = () => (popupContentRef.value = null)

    return {
      rootElRef,
      popupContentRef,
      duos,
      onDuoClick,
      onCtaClick,
      onDuoPopupClick,
      onPopupClose,
    }
  },
})
